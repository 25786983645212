.blink {
    animation: 1.5s linear infinite condemned_blink_effect;
  }
  
  @keyframes condemned_blink_effect {
    0% {
      visibility: hidden;
    }
    50% {
      visibility: hidden;
    }
    100% {
      visibility: visible;
    }
  }
  .dot {
    display: inline-block;
    width: 4.8px;
    height: 4.8px;
    border-radius: 50%;
    background-color: #616161;
    margin-left: 3px;
    margin-bottom: 2px;
    margin-right: 3px;
    margin-top: 2px;
  }
  .sub-job {
    font-family: monospace;
    font-size: 16px;
    font-weight: 550;
    line-height: 20px;
    color: #616161;
    padding-bottom: 5px;
}

.job-date {
  font-family: monospace;
  font-size: 14px;
  line-height: 20px;
  color: #848383;
  padding-bottom: 10px;
}

.resume-sub-title {
  font-family: Roboto Mono;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-weight: 700;
  color: #848383;
  margin-bottom: 40px;
}